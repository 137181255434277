import React from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'

import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'

import { clientCreate } from '../../../actions'

class ModalClientForm extends React.Component {
  state = {
    open: false,
    formData: {
      clientId: (this.props.client && this.props.client.id) || '',
      firstName: (this.props.client && this.props.client.first_name) || '',
      lastName: (this.props.client && this.props.client.last_name) || '',
      email: (this.props.client && this.props.client.email) || '',
      phoneNumber:
        (this.props.client &&
          this.props.client.phone_numbers &&
          this.props.client.phone_numbers.length > 0 &&
          this.props.client.phone_numbers[0].phone_number) ||
        '',
    },
  }

  handleClose = () =>
    this.setState({
      open: false,
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
      },
    })

  handleClickOpen = () => this.setState({ open: true })

  handleSubmit = e => {
    e.preventDefault()
    const data = {
      client: {
        first_name: this.state.formData.firstName,
        last_name: this.state.formData.lastName,
      },
    }
    if (this.state.formData.clientId) {
      data.client.id = this.state.formData.clientId
    }
    if (this.state.formData.phoneNumber) {
      data.client.phone_numbers = [
        { phone_number: this.state.formData.phoneNumber },
      ]
    } else {
      data.client.phone_numbers = []
    }
    data.client.email = this.state.formData.email || null

    const successCallback = () => {
      this.props.enqueueSnackbar(this.props.successMessage, {
        variant: 'info',
        autoHideDuration: 5000,
      })
      const handleClose = this.props.handleClose || this.handleClose
      handleClose()
    }
    this.props.dispatch(clientCreate(data, successCallback))
  }

  handleChange = name => e => {
    const obj = this.state.formData
    obj[name] = e.target.value
    this.setState(obj)
  }

  render() {
    const title = this.props.title
    const open = this.props.open || this.state.open
    const handleClose = this.props.handleClose || this.handleClose
    const triggerButton = (
      <Button variant="outlined" size="small" onClick={this.handleClickOpen}>
        <AddIcon fontSize="small" /> {title}
      </Button>
    )

    return (
      <React.Fragment>
        {!this.props.hideTriggerButton && triggerButton}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          fullWidth
        >
          <form onSubmit={this.handleSubmit}>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    id="first-name"
                    label="Prenume"
                    margin="normal"
                    value={this.state.formData.firstName}
                    onChange={this.handleChange('firstName')}
                    error={Boolean(
                      this.props.errors.client &&
                        this.props.errors.client.first_name
                    )}
                    helperText={
                      this.props.errors.client &&
                      this.props.errors.client.first_name
                    }
                    fullWidth
                    required
                    autoFocus
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="last-name"
                    label="Nume de familie"
                    margin="normal"
                    value={this.state.formData.lastName}
                    onChange={this.handleChange('lastName')}
                    fullWidth
                    error={Boolean(
                      this.props.errors.client &&
                        this.props.errors.client.last_name
                    )}
                    helperText={
                      this.props.errors.client &&
                      this.props.errors.client.last_name
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    id="email"
                    label="Email"
                    margin="normal"
                    value={this.state.formData.email}
                    onChange={this.handleChange('email')}
                    error={Boolean(
                      this.props.errors.client && this.props.errors.client.email
                    )}
                    helperText={
                      this.props.errors.client && this.props.errors.client.email
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputMask
                    mask="+40 (999) 999 999"
                    value={this.state.formData.phoneNumber}
                    onChange={this.handleChange('phoneNumber')}
                  >
                    {() => (
                      <TextField
                        id="phonenumber"
                        label="Telefon"
                        margin="normal"
                        fullWidth
                      />
                    )}
                  </InputMask>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Anulează</Button>
              <Button
                type="submit"
                color="secondary"
                disabled={Boolean(this.props.isFetching)}
              >
                {title}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ client: { isFetching, errors } }) => ({
  isFetching,
  errors,
})
ModalClientForm = connect(mapStateToProps)(ModalClientForm)
ModalClientForm = withSnackbar(ModalClientForm)
export default ModalClientForm
