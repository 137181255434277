import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import CenteredPaper from '../Core/CenteredPaper'
import ThemeBackground from '../Core/ThemeBackground'

import ResetPasswordVerificationForm from './ResetPasswordVerifyForm'

const styles = theme => ({
  root: {
    minHeight: '100vh',
  },
  signupContainer: {
    marginTop: theme.spacing(3),
  },
})

const ResetPasswordVerify = ({ classes: { root, signupContainer } }) => (
  <Grid container className={root}>
    <ThemeBackground />

    <CenteredPaper lg={5} md={6} xs={9}>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Dabanote
      </Typography>
      <Typography
        component="h2"
        variant="subtitle1"
        align="center"
        color="textSecondary"
        gutterBottom
      >
        Seteaza parola noua.
      </Typography>
      <Divider />
      <ResetPasswordVerificationForm />
    </CenteredPaper>
  </Grid>
)

export default withStyles(styles)(ResetPasswordVerify)
