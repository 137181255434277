import * as Type from '../actions'

import { addObjectToState } from 'utils'

export const appointment = (
  state = {
    appointments: [],
    isFetching: false,
    isGetFetching: false,
    isDeleteFetching: false,
    errors: {},
  },
  action
) => {
  switch (action.type) {
    case Type.APPOINTMENTS_GET_REQUEST:
      return { ...state, isGetFetching: true }
    case Type.APPOINTMENTS_GET_SUCCESS:
      return {
        ...state,
        isGetFetching: false,
        appointments: action.appointments,
      }
    case Type.APPOINTMENTS_GET_FAILED:
      return { ...state, isGetFetching: false }
    case Type.APPOINTMENT_CREATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: {},
      }
    case Type.APPOINTMENT_CREATE_SUCCESS:
      return {
        ...state,
        appointments: addObjectToState(
          state.appointments,
          action.appointment,
          'id'
        ),
        isFetching: false,
        errors: {},
      }
    case Type.APPOINTMENT_CREATE_FAILED:
      return {
        ...state,
        isFetching: false,
        errors: action.errors,
      }
    case Type.APPOINTMENT_DELETE_REQUEST:
      return {
        ...state,
        isDeleteFetching: true,
      }
    case Type.APPOINTMENT_DELETE_SUCCESS:
      return {
        ...state,
        appointments: state.appointments.filter(
          appointment => appointment.id !== action.appointment_id
        ),
        isDeleteFetching: false,
      }

    default:
      return state
  }
}
