import React from 'react'
import axios from 'axios'
import { Redirect } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import { Link as RouterLink } from 'react-router-dom'

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import Header from '../Header'

const styles = theme => ({
  icon: {
    color: '#fff',
    marginRight: '10px',
    position: 'relative',
    top: '5px',
  },
})

class ChangePassword extends React.Component {
  state = {
    formData: {
      password: '',
      passwordNew: '',
      passwordConfirm: '',
    },
    errors: {},
    completed: false,
  }
  handleSubmit = async e => {
    e.preventDefault()
    const data = {
      password: this.state.password,
      password_new: this.state.passwordNew,
      password_confirm: this.state.passwordConfirm,
    }

    const authToken = window.localStorage.getItem('authToken')
    const url = `${process.env.REACT_APP_API_URL}/user/password/change`

    try {
      await axios.post(url, data, {
        headers: { Authorization: `JWT ${authToken}` },
      })
      this.props.enqueueSnackbar('Parola a fost schimbata cu succes.', {
        variant: 'info',
        autoHideDuration: 5000,
      })
      this.setState({ completed: true })
    } catch (error) {
      if (error.response.data.validation.errors) {
        this.setState({ errors: error.response.data.validation.errors })
      }
    }
  }

  handleChange = name => e => {
    const obj = this.state.formData
    obj[name] = e.target.value
    this.setState(obj)
  }

  render() {
    if (this.state.completed) {
      return <Redirect to="/console/settings" />
    }

    return (
      <React.Fragment>
        <Header>
          <Typography variant="h6" color="inherit" noWrap>
            <Link component={RouterLink} to="/console/settings">
              <ArrowBackIosIcon className={this.props.classes.icon} />
            </Link>
            Schimbare parolă
          </Typography>
        </Header>

        <Box p={3}>
          <Paper>
            <Box p={3}>
              <form onSubmit={this.handleSubmit}>
                <Grid container>
                  <Grid item xs={4}>
                    <TextField
                      id="password"
                      label="Parolă actuală"
                      margin="normal"
                      value={this.state.formData.password}
                      onChange={this.handleChange('password')}
                      error={Boolean(this.state.errors.password)}
                      helperText={this.state.errors.password}
                      type="password"
                      fullWidth
                      required
                      autoFocus
                    />
                    <TextField
                      id="password-new"
                      label="Parolă nouă"
                      margin="normal"
                      value={this.state.formData.passwordNew}
                      onChange={this.handleChange('passwordNew')}
                      error={Boolean(this.state.errors.password_new)}
                      helperText={this.state.errors.password_new}
                      type="password"
                      fullWidth
                      required
                    />
                    <TextField
                      id="password-confirm"
                      label="Confirmă parolă nouă"
                      margin="normal"
                      value={this.state.formData.passwordConfirm}
                      onChange={this.handleChange('passwordConfirm')}
                      error={Boolean(
                        this.state.errors.password_confirm ||
                          this.state.errors._schema
                      )}
                      helperText={
                        this.state.errors.password_confirm ||
                        this.state.errors._schema
                      }
                      type="password"
                      fullWidth
                      required
                    />

                    <Box mt={3}>
                      <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        size="small"
                      >
                        Salvează
                      </Button>
                      <Button
                        component={RouterLink}
                        to="/console/settings"
                        style={{ marginLeft: '10px' }}
                        size="small"
                      >
                        Anulează
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Paper>
        </Box>
      </React.Fragment>
    )
  }
}

ChangePassword = withSnackbar(ChangePassword)

export default withStyles(styles)(ChangePassword)
