import React, { Component } from 'react'
import { Redirect, withRouter } from 'react-router'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

import Button from '@material-ui/core/Button'
import EmailOutlined from '@material-ui/icons/EmailOutlined'
import LockOutlined from '@material-ui/icons/LockOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'

import { userPasswordResetVerify, recaptchaVerify, recaptchaRequest } from '../../actions'
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3'

const styles = theme => ({
  submit: {
    marginTop: theme.spacing(3),
  },
})

class ResetPasswordVerifyForm extends Component {
  state = {
    formData: {
      password: '',
      confirmPassword: '',
    },
    userData: {
      firstName: '',
      lastName: '',
      email: '',
    },
  }

  async componentDidMount() {
    await this.triggerRecaptcha()
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.recaptchaToken == null &&
      !this.props.recaptchaIsProcessing
    ) {
      await this.triggerRecaptcha()
    }
  }

  async triggerRecaptcha() {
    this.props.dispatch(recaptchaRequest())
    const token = await this.props.googleReCaptchaProps.executeRecaptcha(
      'password_reset_verify'
    )
    this.props.dispatch(recaptchaVerify(token))
  }

  handleChange = name => e => {
    const obj = this.state.formData
    obj[name] = e.target.value
    this.setState(obj)
  }

  handleSubmit = e => {
    e.preventDefault()
    const data = {
      // Information used to identify the user
      user_id: this.props.match.params.user_id,
      password_reset_code: this.props.match.params.password_reset_code,

      // Information for the new password
      password: this.state.formData.password,
      confirm_password: this.state.formData.confirmPassword,

      // Verification of the token
      recaptcha_token: this.props.recaptchaToken,
    }
    this.props.dispatch(userPasswordResetVerify(data))
  }

  render() {
    const { submit } = this.props.classes
    const errors = this.props.errors

    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          id="password"
          label="Parolă"
          type="password"
          margin="normal"
          value={this.state.formData.password}
          onChange={this.handleChange('password')}
          required
          fullWidth
          error={Boolean(errors.password)}
          helperText={errors.password && errors.password[0]}
        />

        <TextField
          id="confirm-password"
          label="Confirmă parola"
          type="password"
          margin="normal"
          value={this.state.formData.confirmPassword}
          onChange={this.handleChange('confirmPassword')}
          required
          fullWidth
          error={Boolean(errors.confirm_password)}
          helperText={errors.confirm_password && errors.confirm_password[0]}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="secondary"
          className={submit}
        >
          Schimba parola
        </Button>
      </form>
    )
  }
}

const mapStateToProps = ({
  password_reset_verify: { user, isFetching, errors },
  human: { recaptchaToken, recaptchaIsProcessing },
}) => ({
  user,
  isFetching,
  errors,
  recaptchaToken,
  recaptchaIsProcessing,
})

ResetPasswordVerifyForm = connect(mapStateToProps)(ResetPasswordVerifyForm)
ResetPasswordVerifyForm = withRouter(ResetPasswordVerifyForm)
ResetPasswordVerifyForm = withGoogleReCaptcha(ResetPasswordVerifyForm)
export default withStyles(styles)(ResetPasswordVerifyForm)
