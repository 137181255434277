import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { withSnackbar } from 'notistack'
import { Link as RouterLink } from 'react-router-dom'

import Box from '@material-ui/core/Box'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import Link from '@material-ui/core/Link'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'

import ModalClientForm from './ModalClientForm'
import ModalConfirmationDialog from '../ModalConfirmationDialog'
import Header from '../Header'

import { clientDelete, getAppointments } from '../../../actions'

const styles = theme => ({
  tableRow: {
    '& button': {
      color: '#ddd',
    },
    '&:hover button': {
      color: '#999',
    },
  },
  checkbox: {
    width: '90px',
  },
  icon: {
    marginRight: '5px',
  },
})

class Clients extends React.Component {
  state = {
    open: false,
    confirmationOpen: false,
    client: {},
  }

  handleOpen = client => () => this.setState({ open: true, client: client })

  handleClose = () => this.setState({ open: false, client: {} })

  handleDeleteConfirmation = client => () =>
    this.setState({ confirmationOpen: true, client: client })

  handleConfirmationClose = () =>
    this.setState({ confirmationOpen: false, client: {} })

  handleDelete = () => {
    this.props.dispatch(clientDelete(this.state.client.id)).then(() => {
      this.props.dispatch(getAppointments())
      this.props.enqueueSnackbar('Client deleted successfully.', {
        variant: 'info',
        autoHideDuration: 5000,
      })
      this.handleConfirmationClose()
    })
  }

  render() {
    return (
      <React.Fragment>
        <Header>
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography variant="h6" color="inherit" noWrap>
                Clienți
              </Typography>
            </Grid>
            <Grid item xs={8} md={9}>
              <ModalClientForm
                title="Adaugă client"
                successMessage="Client added successfully."
              />
            </Grid>
          </Grid>
        </Header>

        <Box p={3}>
          <Paper>
            <Table className={this.props.classes.table} size="medium">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    className={this.props.classes.checkbox}
                  />
                  <TableCell>Nume client</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell align="right">Telefon</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.clients.map(client => (
                  <TableRow
                    key={client.id}
                    className={this.props.classes.tableRow}
                    hover
                  >
                    <TableCell
                      align="center"
                      className={this.props.classes.checkbox}
                    >
                      <IconButton
                        aria-label="Edit"
                        className={this.props.classes.icon}
                        size="small"
                        onClick={this.handleOpen(client)}
                      >
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                      <IconButton
                        aria-label="Delete"
                        className={this.props.classes.icon}
                        size="small"
                        onClick={this.handleDeleteConfirmation(client)}
                      >
                        <DeleteOutlineIcon fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link
                        component={RouterLink}
                        to={`/console/clients/${client.id}`}
                      >
                        {client.first_name} {client.last_name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link href={`mailto:${client.email}`}>
                        {client.email}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      {client.phone_numbers.map(number => number.phone_number)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        </Box>
        <ModalClientForm
          open={this.state.open}
          handleClose={this.handleClose}
          client={this.state.client}
          key={this.state.client.id}
          title="Editează client"
          successMessage="Client edited successfully."
          hideTriggerButton
        />
        <ModalConfirmationDialog
          open={this.state.confirmationOpen}
          handleClose={this.handleConfirmationClose}
          handleSubmit={this.handleDelete}
          title="Șterge client"
          isFetching={this.props.isDeleteFetching}
          message={`Are you sure you want to delete ${this.state.client.first_name} ${this.state.client.last_name}?`}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ client: { clients, isDeleteFetching } }) => ({
  clients,
  isDeleteFetching,
})
Clients = connect(mapStateToProps)(Clients)
Clients = withSnackbar(Clients)

export default withStyles(styles)(Clients)
