import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import AvatarContent from './AvatarContent'

const styles = theme => ({
  brand: {
    marginBottom: '14px',
  },
})

const SidebarContent = ({ classes, location }) => (
  <React.Fragment>
    <div className={classes.brand}>
      <Typography component="h1" variant="h6" align="center">
        <strong>Dabanote</strong>
      </Typography>
    </div>

    <AvatarContent />

    <List component="nav">
      <ListItem
        button
        selected={location.pathname === '/console'}
        component={RouterLink}
        to="/console"
      >
        <ListItemText primary="Calendar" />
      </ListItem>
      <ListItem
        button
        selected={location.pathname === '/console/clients'}
        component={RouterLink}
        to="/console/clients"
      >
        <ListItemText primary="Clienti" />
      </ListItem>
      <ListItem button component={RouterLink} to="/console">
        <ListItemText primary="Contracte" />
      </ListItem>
    </List>
  </React.Fragment>
)

export default withStyles(styles)(withRouter(SidebarContent))
