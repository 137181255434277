import * as Type from '../actions'

export const signup = (
  state = {
    isFetching: false,
    user: null,
    errors: {},
  },
  action
) => {
  switch (action.type) {
    case Type.USER_SIGNUP_REQUEST:
      return { ...state, isFetching: true, errors: {} }
    case Type.USER_SIGNUP_SUCCESS:
      return { ...state, isFetching: false, user: action.user, errors: {} }
    case Type.USER_SIGNUP_FAILED:
      return { ...state, isFetching: false, errors: action.errors }
    default:
      return state
  }
}

export const login = (
  state = {
    isFetching: false,
    user: null,
    errors: {},
  },
  action
) => {
  switch (action.type) {
    case Type.USER_LOGIN_REQUEST:
      return { ...state, isFetching: true, errors: {} }
    case Type.USER_LOGIN_SUCCESS:
      return { ...state, isFetching: false, user: action.user, errors: {} }
    case Type.USER_LOGIN_FAILED:
      return { ...state, isFetching: false, errors: action.errors }
    case Type.USER_LOGOUT:
      return { ...state, user: null, isFetching: false, errors: {} }
    default:
      return state
  }
}

export const password_reset_verify = (
  state = {
    isFetching: false,
    user: null,
    errors: {},
  },
  action
) => {
  switch (action.type) {
    case Type.USER_PASSWORD_RESET_VERIFY_REQUEST:
      return { ...state, isFetching: true, errors: {} }
    case Type.USER_PASSWORD_RESET_VERIFY_SUCCESS:
      return { ...state, isFetching: false, user: action.user, errors: {} }
    case Type.USER_PASSWORD_RESET_VERIFY_FAILED:
      return { ...state, isFetching: false, errors: action.errors }
    default:
      return state
  }
}

export const human = (
  state = {
    recaptchaToken: null,
    recaptchaIsProcessing: false
  },
  action
) => {
  switch (action.type) {
    case Type.RECAPTCHA_REQUEST:
      return { ...state, recaptchaIsProcessing: true }
    case Type.RECAPTCHA_VERIFY_SUCCESS:
      return { ...state, recaptchaToken: action.recaptchaToken, recaptchaIsProcessing: false }
    case Type.RECAPTCHA_RESET:
      return { ...state, recaptchaToken: null, recaptchaIsProcessing: false }
    default:
      return state
  }
}
