import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'

import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'

import CenteredPaper from '../Core/CenteredPaper'
import ThemeBackground from '../Core/ThemeBackground'

import LoginForm from './LoginForm'

const styles = theme => ({
  root: {
    minHeight: '100vh',
  },
})

const Login = ({ classes: { root } }) => (
  <Grid container className={root}>
    <ThemeBackground />

    <CenteredPaper lg={5} md={6} xs={9}>
      <Typography component="h1" variant="h4" align="center" gutterBottom>
        Dabanote
      </Typography>
      <Typography
        component="h2"
        variant="subtitle1"
        align="center"
        color="textSecondary"
        gutterBottom
      >
        Logare în cont.
      </Typography>
      <Divider />
      <LoginForm />

      <Box pt={2} pb={2}>
        <Typography variant="body2" align="center">
          <Link component={RouterLink} to="/password-reset">
            Ai uitat parola?
          </Link>
        </Typography>
      </Box>

      <Divider />

      <Box pt={2}>
        <Typography variant="subtitle1" color="textSecondary" align="center">
          Nu ai cont?
          <br />
          <Link component={RouterLink} color="secondary" to="/signup">
            Înregistrează-te
          </Link>
        </Typography>
      </Box>
    </CenteredPaper>
  </Grid>
)

export default withStyles(styles)(Login)
