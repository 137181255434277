import * as Type from '../actions'

import { addObjectToState } from 'utils'

export const client = (
  state = {
    clients: [],
    isFetching: false,
    isDeleteFetching: false,
    errors: {},
  },
  action
) => {
  switch (action.type) {
    case Type.CLIENTS_REQUEST_SUCCESS:
      return {
        ...state,
        clients: action.clients,
      }
    case Type.APPOINTMENT_CREATE_SUCCESS:
      return {
        ...state,
        clients: addObjectToState(state.clients, action.client, 'id'),
      }
    case Type.CLIENT_CREATE_REQUEST:
      return {
        ...state,
        isFetching: true,
        errors: {},
      }
    case Type.CLIENT_DELETE_REQUEST:
      return {
        ...state,
        isDeleteFetching: true,
      }
    case Type.CLIENT_CREATE_SUCCESS:
      return {
        ...state,
        clients: addObjectToState(state.clients, action.client, 'id'),
        isFetching: false,
        errors: {},
      }
    case Type.CLIENT_DELETE_SUCCESS:
      return {
        ...state,
        clients: state.clients.filter(
          client => client.id !== action.client_id
        ),
        isDeleteFetching: false,
      }
    case Type.CLIENT_CREATE_FAILED:
      return {
        ...state,
        isFetching: false,
        errors: action.errors,
      }
    default:
      return state
  }
}
