import * as Type from './types'
import axios from 'axios'

const userSignupRequest = () => ({
  type: Type.USER_SIGNUP_REQUEST,
})

const userSignupSuccess = user => ({
  type: Type.USER_SIGNUP_SUCCESS,
  user,
})

const userSignupFailed = errors => ({
  type: Type.USER_SIGNUP_FAILED,
  errors,
})

export const userSignup = data => async dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/user/signup`

  dispatch(userSignupRequest())
  dispatch(recaptchaReset())

  try {
    const response = await axios.post(url, data)
    const user = response.data.user
    window.localStorage.setItem('authToken', response.data.access_token)
    dispatch(userSignupSuccess(user))
  } catch (error) {
    if (error.response) {
      const errors = error.response.data.validation.errors
      dispatch(userSignupFailed(errors))
    }
  }
}

export const userLoginRequest = { type: Type.USER_LOGIN_REQUEST }

const userLoginSuccess = user => ({
  type: Type.USER_LOGIN_SUCCESS,
  user,
})

const userLoginFailed = errors => ({
  type: Type.USER_LOGIN_FAILED,
  errors,
})

export const userLogin = data => async dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/user/login`

  dispatch(userLoginRequest)
  dispatch(recaptchaReset())

  try {
    const response = await axios.post(url, data)
    const user = response.data.user
    window.localStorage.setItem('authToken', response.data.access_token)
    dispatch(userLoginSuccess(user))
  } catch (error) {
    if (error.response) {
      const errors = error.response.data.validation.errors || {}
      if (!errors.email && !errors.password) {
        errors.email = error.response.data.validation.message
      }
      dispatch(userLoginFailed(errors))
    }
  }
}

const userAccountDetailsRequest = () => ({
  type: Type.USER_ACCOUNT_DETAILS_REQUEST,
})

const userAccountDetailsSuccess = user => ({
  type: Type.USER_ACCOUNT_DETAILS_SUCCESS,
  user,
})

const userAccountDetailsFailed = () => ({
  type: Type.USER_ACCOUNT_DETAILS_FAILED,
})

export const userAccountDetails = () => async dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/user/info`
  const authToken = window.localStorage.getItem('authToken')
  if (!authToken) {
    dispatch(userAccountDetailsFailed())
    return
  }

  dispatch(userAccountDetailsRequest())

  try {
    const response = await axios.get(url, {
      headers: { Authorization: `JWT ${authToken}` },
    })
    const user = response.data.user
    dispatch(userAccountDetailsSuccess(user))
  } catch (error) {
    window.localStorage.removeItem('authToken')
    dispatch(userAccountDetailsFailed())
  }
}

export const userLogout = () => {
  window.localStorage.removeItem('authToken')
  return {
    type: Type.USER_LOGOUT,
  }
}

export const userPasswordResetRequest = { type: Type.USER_PASSWORD_RESET_REQUEST }

export const userPasswordReset = data => async dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/user/password/reset_trigger`

  dispatch(userPasswordResetRequest)
  dispatch(recaptchaReset())

  try {
    const response = await axios.post(url, data)
    dispatch(userPasswordResetSuccess())
  } catch (error) {
    if (error.response) {
      const errors = error.response.data.validation.errors || {}
      dispatch(userPasswordResetFailed(errors))
    }
  }
}

const userPasswordResetSuccess = () => ({
  type: Type.USER_PASSWORD_RESET_SUCCESS,
})

const userPasswordResetFailed = errors => ({
  type: Type.USER_PASSWORD_RESET_FAILED,
  errors,
})

export const userPasswordResetVerifyRequest = { type: Type.USER_PASSWORD_RESET_VERIFY_REQUEST }

export const userPasswordResetVerify = data => async dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/user/password/reset_verify`

  dispatch(userPasswordResetVerifyRequest)
  dispatch(recaptchaReset())

  try {
    const response = await axios.post(url, data)
    dispatch(userPasswordResetVerifySuccess())
  } catch (error) {
    if (error.response) {
      const errors = error.response.data.validation.errors || {}
      dispatch(userPasswordResetVerifyFailed(errors))
    }
  }
}

const userPasswordResetVerifySuccess = () => ({
  type: Type.USER_PASSWORD_RESET_VERIFY_SUCCESS,
})

const userPasswordResetVerifyFailed = errors => ({
  type: Type.USER_PASSWORD_RESET_VERIFY_FAILED,
  errors,
})

const recaptchaVerifySuccess = recaptchaToken => ({
  type: Type.RECAPTCHA_VERIFY_SUCCESS,
  recaptchaToken,
})

export const recaptchaVerify = token => async dispatch => {
  dispatch(recaptchaVerifySuccess(token))
}

export const recaptchaReset = () => ({
  type: Type.RECAPTCHA_RESET,
})

export const recaptchaRequest = () => ({
  type: Type.RECAPTCHA_REQUEST,
})
