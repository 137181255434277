import React from 'react'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'

import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

import { noteCreate } from 'actions'

class ModalNoteForm extends React.Component {
  state = {
    open: false,
    formData: {
      noteId: (this.props.note && this.props.note.id) || '',
      appointmentId: (this.props.note && this.props.note.appointment_id) || '',
      content: (this.props.note && this.props.note.content) || '',
    },
  }

  handleClose = () =>
    this.setState({
      open: false,
      formData: {
        noteId: '',
        appointmentId: '',
        content: '',
      },
    })

  handleClickOpen = () => this.setState({ open: true })

  handleChange = name => e => {
    const obj = this.state.formData
    obj[name] = e.target.value
    this.setState(obj)
  }

  handleSubmit = e => {
    e.preventDefault()
    const data = {
      note: {
        content: this.state.formData.content,
      },
    }

    if (this.state.formData.appointmentId) {
      data.note.appointment_id = this.state.formData.appointmentId
    }

    if (this.state.formData.noteId) {
      data.note.id = this.state.formData.noteId
    }

    const successCallback = () => {
      this.props.enqueueSnackbar(this.props.successMessage, {
        variant: 'info',
        autoHideDuration: 5000,
      })
      const handleClose = this.props.handleClose || this.handleClose
      handleClose()
    }
    this.props.dispatch(
      noteCreate(this.props.client.id, data, successCallback)
    )
  }

  render() {
    const open = this.props.open || this.state.open
    const client = this.props.client
    const title = this.props.title
    const appointments = this.props.appointments.filter(
      appointment => appointment.client_id === client.id
    )
    const handleClose = this.props.handleClose || this.handleClose

    const triggerButton = (
      <Button variant="outlined" size="small" onClick={this.handleClickOpen}>
        <AddIcon fontSize="small" /> {title}
      </Button>
    )

    return (
      <React.Fragment>
        {!this.props.hideTriggerButton && triggerButton}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          fullWidth
        >
          <form onSubmit={this.handleSubmit}>
            <DialogTitle id="form-dialog-title">
              {title} pentru {client.first_name} {client.last_name}
            </DialogTitle>
            <DialogContent>
              <TextField
                id="content"
                rows={3}
                rowsMax={3}
                variant="outlined"
                error={Boolean(
                  this.props.errors.note && this.props.errors.note.content
                )}
                helperText={
                  this.props.errors.note && this.props.errors.note.content
                }
                onChange={this.handleChange('content')}
                value={this.state.formData.content}
                required
                multiline
                fullWidth
                autoFocus
              />
              <FormControl fullWidth>
                <InputLabel htmlFor="client_id">
                  Selectează data programării
                </InputLabel>
                <Select
                  value={this.state.formData.appointmentId}
                  onChange={this.handleChange('appointmentId')}
                >
                  <MenuItem disabled>Selectează data programării</MenuItem>
                  <MenuItem value="">---</MenuItem>
                  {appointments.map(appointment => (
                    <MenuItem
                      value={appointment.id}
                      key={appointment.id}
                    >
                      {appointment.date_representation}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose}>Anulează</Button>
              <Button
                type="submit"
                color="secondary"
                disabled={Boolean(this.props.isFetching)}
              >
                {title}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({
  note: { isFetching, errors },
  appointment: { appointments },
}) => ({
  appointments,
  isFetching,
  errors,
})

ModalNoteForm = connect(mapStateToProps)(ModalNoteForm)
ModalNoteForm = withSnackbar(ModalNoteForm)
export default ModalNoteForm
