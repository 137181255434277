import * as Type from './types'
import axios from 'axios'

const getAppointmentsRequest = { type: Type.APPOINTMENTS_GET_REQUEST }

const getAppointmentsSuccess = appointments => ({
  type: Type.APPOINTMENTS_GET_SUCCESS,
  appointments,
})

const getAppointmentsFail = { type: Type.APPOINTMENTS_GET_FAILED }

export const getAppointments = () => async dispatch => {
  const url = `${process.env.REACT_APP_API_URL}/appointments`
  const authToken = window.localStorage.getItem('authToken')

  dispatch(getAppointmentsRequest)

  try {
    const response = await axios.get(url, {
      headers: { Authorization: `JWT ${authToken}` },
    })
    const appointments = response.data.appointments

    dispatch(getAppointmentsSuccess(appointments))
  } catch (error) {
    dispatch(getAppointmentsFail)
  }
}

const createAppointmentRequest = { type: Type.APPOINTMENT_CREATE_REQUEST }

const appointmentCreateSuccess = (appointment, client) => ({
  type: Type.APPOINTMENT_CREATE_SUCCESS,
  appointment,
  client,
})

const appointmentCreateFailed = errors => ({
  type: Type.APPOINTMENT_CREATE_FAILED,
  errors,
})

export const appointmentCreate = (data, successCallback) => dispatch => {
  let url = `${process.env.REACT_APP_API_URL}/appointments`
  const authToken = window.localStorage.getItem('authToken')

  const appointment_id = data.appointment.id
  const client_id = data.appointment.client_id
  const request = appointment_id ? axios.put : axios.post
  url = appointment_id ? `${url}/${client_id}/${appointment_id}` : url
  delete data.appointment.id

  dispatch(createAppointmentRequest)

  return request(url, data, { headers: { Authorization: `JWT ${authToken}` } })
    .then(response => {
      const appointment = response.data.appointment
      const client = response.data.client
      dispatch(appointmentCreateSuccess(appointment, client))
      successCallback()
    })
    .catch(error => {
      if (error.response) {
        const errors = error.response.data.validation.errors
        dispatch(appointmentCreateFailed(errors))
      }
    })
}

const appointmentDeleteRequest = { type: Type.APPOINTMENT_DELETE_REQUEST }

const appointmentDeleteSuccess = appointment_id => ({
  type: Type.APPOINTMENT_DELETE_SUCCESS,
  appointment_id,
})

export const appointmentDelete = appointment => dispatch => {
  let url = `${process.env.REACT_APP_API_URL}/appointments/${appointment.client_id}/${appointment.id}`
  const authToken = window.localStorage.getItem('authToken')

  dispatch(appointmentDeleteRequest)
  return axios
    .delete(url, {
      headers: { Authorization: `JWT ${authToken}` },
    })
    .then(() => dispatch(appointmentDeleteSuccess(appointment.id)))
}
